import React, { useState, useEffect } from 'react';

const TypeWriter  = ({ text, delay } : {text: string, delay: number}) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [writing, setWriting] = useState(true);

    useEffect(() => {
        if(writing) {
            if (currentIndex < text.length) {
                const timeout = setTimeout(() => {
                    setCurrentText(prevText => prevText + text[currentIndex]);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }, delay);

                return () => clearTimeout(timeout);
            } else {
                const timeout = setTimeout(() => {
                    setWriting(false);
                }, 3000);

                return () => clearTimeout(timeout);
            }
        } else {
            if (currentIndex > 0) {
                const timeout = setTimeout(() => {
                    setCurrentText(prevText => prevText.slice(0, -1));
                    setCurrentIndex(prevIndex => prevIndex - 1);
                }, delay);

                return () => clearTimeout(timeout);
            } else {
                setWriting(true);
            }
        }
    }, [currentIndex, delay, text, writing]);

    return <span>{currentText}</span>;
};

export default TypeWriter;