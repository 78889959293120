import {useState} from "react";
import {ReactComponent as MailSVG } from "./svg/email.svg";
import {ReactComponent as GithubSVG } from "./svg/github.svg";
import {ReactComponent as LinkedInSVG } from "./svg/linkedin.svg";

enum ContactType {
    EMAIL, GITHUB, LINKEDIN
}

function Contact() {
    return (
        <div style={styles.contact}>
            <h1 style={styles.contactTitle}>Contact</h1>
            <div style={{display: 'flex', flexWrap: 'wrap' as 'wrap', flexDirection: 'row' as 'row', justifyContent: 'center', alignItems: 'center', gap: '10px', marginBottom: '50px'}}>
                <Chip text="gautiervictor1@gmail.com" contact={ContactType.EMAIL} link={'mailto:gautiervictor1@gmail.com'}/>
                <Chip text="github.com/vriliox" contact={ContactType.GITHUB} link={'https://github.com/vriliox'}/>
                <Chip text="linkedin.com/in/gautiervictor1" contact={ContactType.LINKEDIN} link={'https://linkedin.com/in/gautiervictor1'}/>
            </div>
        </div>
    );
}

const openInNewTab = (url:string) => {
    window.open(url, "_blank", "noreferrer");
};

function Chip({text, contact, link} : {text : string, contact : ContactType, link:string}) {
    const [hover, setHover] = useState(false);

    return (
        <div style={{...chipStyles.chip, backgroundColor: hover ? '#373344' : 'rgba(0,0,0,0)'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => openInNewTab(link)}>
            {contact === ContactType.EMAIL && <MailSVG style={{...chipStyles.icon, fill: hover ? '#F2EDDA' : '#373344'}}/>}
            {contact === ContactType.GITHUB && <GithubSVG style={{...chipStyles.icon, fill: hover ? '#F2EDDA' : '#373344'}}/>}
            {contact === ContactType.LINKEDIN && <LinkedInSVG style={{...chipStyles.icon, fill: hover ? '#F2EDDA' : '#373344'}}/>}
            <p style={{...chipStyles.chipText, color: hover ? '#F2EDDA' : '#373344'}}>{text}</p>
        </div>
    );
}

const chipStyles = {
    chip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px 20px 20px 20px',
        padding: '5px',
        margin: '5px',
        transition: 'all 0.2s ease',
    }, icon: {
        height: '30px',
        marginRight: '10px',
        marginLeft: '10px',
        cursor: 'pointer',
    }, chipText: {
        margin: 0,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.0rem',
        fontWeight: 500,
        marginRight: '10px',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
    }
}

const styles = {
    contact: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F2EDDA',
    }, contactTitle: {
        fontFamily: 'Victoria, sans-serif',
        fontSize: '3.3rem',
        fontWeight: 700,
        marginBottom: '20px',
        color: '#373344',
    }
}

export default Contact;
