import {useState} from "react";
import TypeWriter from "./TypeWriter";

function AboutMe ({isPortrait} : {isPortrait : boolean}) {
    const [skillsHover, setSkillsHover] = useState(false);
    return (
        <section style={{...styles.background, flexDirection: isPortrait ? 'column' as 'column' : 'row' as 'row'}}>
            <div style={{width: isPortrait ? '50%' : '20%', height: 'auto', position: 'relative' as 'relative', marginTop: isPortrait ? '2vh' : '0'}}>
                <img src={`images/me.jpg`} alt="Me" style={styles.imgMe}/>
                {!isPortrait && <Chip text={'France'} icon={'beret.png'} style={{position: 'absolute' as 'absolute', left: '85%', top: '5%'}}/>}
                {!isPortrait && <Chip text={'18 years old'} icon={'age.png'} style={{position: 'absolute' as 'absolute', left: '75%', top: '30%', width: '180px'}}/>}
                {!isPortrait && <Chip text={'IUT of La Rochelle'} icon={'school.png'} style={{position: 'absolute' as 'absolute', left: '-35%', top: '20%'}}/>}
            </div>
            <div style={{...styles.textContainer, width: isPortrait ? '90%' : '30%'}}>
                <h2 style={styles.title}>Hi, <TypeWriter text={'i\'m Victor.'} delay={200}/></h2>
                <p style={styles.text}>I am a 18 year old student in computer science at the University of La Rochelle. I've had a passion for computers since I was young, and my curiosity knows no bounds. I love discovering new languages and technologies to create new things.</p>
                <div style={styles.skillsContainer}>
                    <h3 style={{fontFamily: 'Poppins, sans-serif', fontSize: '1.5rem', fontWeight: 700, color: '#E06351', margin: 0}}>Skills</h3>
                    <div style={{display: 'flex', gap: skillsHover ? '15px' : '5px', flexWrap: 'wrap' as 'wrap', flexDirection: 'row' as 'row', justifyContent: 'center', transition: 'all ease 0.5s'}} onMouseEnter={() => setSkillsHover(true)} onMouseLeave={() => setSkillsHover(false)}>
                        <Skill name={'Java'} img={'java.png'}/>
                        <Skill name={'C++'} img={'cpp.png'}/>
                        <Skill name={'C#'} img={'csharp.png'}/>
                        <Skill name={'React'} img={'react-native.png'}/>
                        <Skill name={'HTML'} img={'html.png'}/>
                        <Skill name={'CSS'} img={'css.png'}/>
                        <Skill name={'SQL'} img={'sql.png'}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Chip ({text, icon, style} : {text : string, icon : string, style? : any}) {
    const [hover, setHover] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
             style={{...chipStyles.chip, ...style, boxShadow: hover ? '0px 0px 0px #000000' : '1px 1px 3px #000000', transform: hover ? 'translate(1px, 1px)' : 'translate(0px, 0px)', transition: 'all 0.1s ease-in-out',
        }}>
            <img src={`images/${icon}`} alt={text} style={chipStyles.icon}/>
            <p style={chipStyles.chipText}>{text}</p>
        </div>
    )
}

function Skill ({name, img} : {name : string, img : string}) {
    const [hover, setHover] = useState(false);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column' as 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            boxShadow: hover ? '8px 8px 16px #000000' : '2px 2px 4px #000000',
            borderRadius: '10px',
            padding: '10px',
            width: '100px',
            transition: 'all 0.2s ease',
            backgroundColor: 'white',
        }} onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)}>
            <img src={`images/${img}`} alt={name} style={{height: '50px'}}/>
            <p style={{fontFamily: 'Poppins, sans-serif', fontSize: '1.0rem', fontWeight: 500, color: '#E06351', margin: 0}}>{name}</p>
        </div>
    )
}

const chipStyles = {
    chip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E06351',
        borderRadius: '20px 20px 20px 20px',
        padding: '5px',
        margin: '5px',
        animation: 'chip 1.5s ease infinite',
    }, icon: {
        height: '30px',
        marginRight: '10px',
        marginLeft: '10px',
    }, chipText: {
        color: 'white',
        margin: 0,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.0rem',
        fontWeight: 500,
        marginRight: '10px',
    }
}

const styles = {
    background: {
        position: 'relative' as 'relative',
        background: 'center / cover no-repeat url("images/AboutMeBack.png")',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    }, imgMe: {
        borderRadius: '5%',
        height: '100%',
        width: '100%',
        boxShadow: '3px 3px 8px #000000',
    }, textContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }, title: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2.5rem',
        fontWeight: 700,
        color: 'white',
        backgroundColor: '#E06351',
    }, text: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: 'white',
        textShadow: '2px 2px 4px #000000',
        textAlign: 'justify' as 'justify',
    }, skillsContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        borderRadius: '10px',
        padding: '10px',
        maxWidth: '120%',
        flexWrap: 'wrap' as 'wrap',
    }
}

export default AboutMe;