import React, {useEffect, useState} from 'react';
import './App.css';
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Contact from "./Contact";

function App() {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        });

        return () => window.removeEventListener('resize', () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        });
    }, []);


    return (
    <div className="App">
        <header className="App-header">
            <video style={styles.video} src={isPortrait ? "videos/MonitorPhone.mp4" : "videos/Monitor.mp4"} loop autoPlay muted>Your browser does not support the video tag.</video>
            <div style={{
                top: isPortrait? '10vh' : '30vh',
                left: isPortrait ? '0' : '20%',
                width: isPortrait ? '100%' : 'auto',
                position: 'absolute' as 'absolute',
            }}>
                <h1 style={styles.title}>Gautier Victor</h1>
                <p style={styles.subtitle}>(Future) Software Developer</p>
            </div>
        </header>
        <Projects isPortrait={isPortrait} />
        <AboutMe isPortrait={isPortrait}/>
        <Contact />
    </div>
    );
}

const styles = {
    video: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover' as 'cover',
    }, title: {
        color: 'white',
        fontFamily: 'Victoria, sans-serif',
        fontSize: '3.3rem',
        textShadow: '2px 2px 4px #000000',
        marginBottom: 0,
        fontWeight: 100,
    }, subtitle : {
        color: 'white',
        fontSize: '1.0rem',
        marginTop: 0,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 100,
        textShadow: '2px 2px 4px #000000'
    }
}

export default App;
